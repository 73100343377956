// @packages
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
// @scripts
import "./UrlForm.css";
import Modal from "../Modal";

const FormComponent = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoAnnotations, setVideoAnnotations] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (errorMessage) {
      const timeoutId = setTimeout(() => {
        setErrorMessage("");
        setUrl("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [errorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!url.trim() || errorMessage) {
      setErrorMessage("Please enter a valid TikTok URL");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/googleVideo/labelDetection`,
        { videoUrl: url }
      );
      if (response.data.success) {
        const { video_labels } = response.data;

        const wordsArray = video_labels
          .flatMap((label) => label.split(", "))
          .filter((word) => word.trim() !== "");
        const uniqueWordsArray = [...new Set(wordsArray)];

        setVideoAnnotations(uniqueWordsArray);
        setModalOpen(true);
      } else {
        setErrorMessage(`Error retreiving analysis ${response?.data?.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(`Error retreiving analysis: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  function extractContent(inputString, removeString) {
    const substring = removeString;
    const index = inputString.indexOf(substring);
    if (index !== -1) {
      return inputString.substring(index + substring.length).trim();
    } else {
      return "Substring not found";
    }
  }

  const handleChange = (e) => {
    let inputValue = e.target.value;
    const tiktokCopyLinkMsg = "Watch more content recommended just for you. ";
    const indexOfQuestionMark = inputValue.indexOf("?");
    if (indexOfQuestionMark !== -1) {
      inputValue = inputValue.substring(0, indexOfQuestionMark);
      setUrl(inputValue);
    }

    if (inputValue.startsWith("https://www.tiktok.com/") || inputValue === "") {
      setUrl(inputValue);
      setErrorMessage("");
    } else if (inputValue.startsWith(tiktokCopyLinkMsg)) {
      setUrl(extractContent(inputValue, tiktokCopyLinkMsg));
    } else {
      setErrorMessage("Please enter a valid TikTok URL");
      setUrl(inputValue);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setUrl("");
    setTimeout(() => {
      setModalOpen(false);
    }, 700);
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          value={url}
          onChange={handleChange}
          placeholder='Copy and paste URL'
          className='form-input'
        />
        <button type='submit' className='bttn'>
          Submit
        </button>
        {errorMessage && <div className='error-message'>{errorMessage}</div>}
        {loading ? (
          <div className='spinner-container'>
            <FontAwesomeIcon className='spinner' icon={faSpinner} spin />
            <div className='message'>Generating Analysis...</div>
          </div>
        ) : null}
      </form>
      <Modal
        displayText={videoAnnotations}
        isOpen={modalOpen}
        onClose={closeModal}
        headerText={"Detected Labels from TikTok Video"}
      />
    </div>
  );
};

export default FormComponent;
