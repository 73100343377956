// @packages
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @scripts
import "./App.css";
import backgroundImage from "./images/pexels-lukas-kloeppel.jpg";
import UrlComponent from "./components/UrlForm";

function App() {
  return (
    <div className='App' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='dim-overlay'>
        <header className='App-header'>
          <div className='message-items'>
            <p>Enter the URL of the TikTok video to annotate</p>
            <div className='icon-container'>
              <FontAwesomeIcon className='icon' icon={faTiktok} />
            </div>
          </div>
          <UrlComponent />
        </header>
      </div>
    </div>
  );
}

export default App;
