// @packages
import { useRef } from "react";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
// @scripts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClickOutside from "../../helpers/clickOutside";
import "./Modal.css";

const Modal = ({ isOpen, onClose, displayText, headerText }) => {
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => {
    onClose();
  });
  return (
    <div>
      {isOpen && (
        <div className='modal-overlay'>
          <div className='modal' ref={modalRef}>
            <div className='message-items'>
              <h2>{headerText}</h2>
              <FontAwesomeIcon className='modal-icon' icon={faTiktok} />
            </div>
            {displayText && displayText[0] === "" ? (
              <div>No speech transcriptions found</div>
            ) : (
              displayText.map((item, index) => <div key={index}>{item}</div>)
            )}
            <button className='modal-bttn' onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
